import { localContext } from '../entities/local_context';

//const stickiesSize = 100;
//const stickiesPadding = 20;
//
//let colors = [
//    "#FFFFFF",
//    "#E197C0",
//    "#979797",
//    "#B03229",
//    "#000000",
//    "#725246",
//    "#7BAC7A",
//    "#4EAFCE",
//    "#F5DC71",
//    "#3D6BA7",
//    "#E9A04D",
//    "#9871CF",
//];
//
//let cursorX = 0;
//for (let i = 0; i < 10; ++i)
//{
//    let stickyNote = new StickyNote(cursorX, 0, `${i}`, colors[i]);
//    cursorX += stickiesSize + stickiesPadding;
//    localContext.activity.addEntity(stickyNote);
//}
//
//let image = new ImageUpload(200, 200,
//                            "https://koala-data-bucket.s3.us-west-2.amazonaws.com/default_images/chicken.png");
//localContext.activity.addEntity(image);
//image.onLoaded = () => {
//    localContext.activity.addEntity(image);
//}

localContext.activity.loadFromStore();
localContext.camera.fitToContent(1.3, { x: 0, y: 0 });


