import ToolBarManager from '../ui/toolbar';
import ControlsManager from './controls';
import SideBarManager from '../ui/sidebar';
import { Camera } from './camera';
import DragAndDropManager from './drag_and_drop';
import { Activity } from './activity';
import TopBarManager from '../ui/topBar';
import ContextualMenu from '../ui/contextualMenu';

class LocalContext {
    public camera : Camera;
    public contextualMenu : ContextualMenu;

    #activity : Activity;
    #toolBarManager : ToolBarManager;
    #sideBarManager : SideBarManager;
    #topBarManager : TopBarManager;
    #controlsManager : ControlsManager;
    #dragAndDropManager : DragAndDropManager;


    constructor()
    {
        this.#activity = new Activity();
        this.camera = new Camera(this.#activity.canvas);
        this.#toolBarManager = new ToolBarManager();
        this.#sideBarManager = new SideBarManager();
        this.#topBarManager = new TopBarManager();
        this.#controlsManager = new ControlsManager(this.#activity.canvas, this.#toolBarManager);
        this.#dragAndDropManager = new DragAndDropManager(document.getElementsByClassName("upper-canvas")[0] as HTMLElement);
        this.contextualMenu = new ContextualMenu();
    }

    get activity()
    {
        return this.#activity;
    }

    get topBarManager()
    {
        return this.#topBarManager;
    }
}

let localContext = new LocalContext();

export { localContext };