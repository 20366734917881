class ToolBarManager
{
    buttons: {[key: string] : HTMLElement} = {};
    currentlySelectedTool: string;

    toggleToolSelected(toolName: string, isSelected: boolean)
    {
        this.buttons[toolName].style.background = isSelected ? "#FFC145" : "#FFFFFF";
        (this.buttons[toolName].childNodes[1] as HTMLElement).style.display = isSelected ? "none" : "block";
        (this.buttons[toolName].childNodes[3] as HTMLElement).style.display = isSelected ? "block" : "none";
    }

    onMouseHover(toolName: string, isEntering: boolean)
    {
        if (toolName === this.currentlySelectedTool)
        {
            this.buttons[toolName].style.background = isEntering ? "#f3b12e" : "#FFC145";
        }
        else
        {
            this.buttons[toolName].style.background = isEntering ? "#F8F8F8" : "#FFFFFF";
        }
    }

    onToolSelected(toolName: string)
    {
        const event = new CustomEvent('tool_changed', {
            detail: {
                toolName: toolName,
            }
        });
        this.toggleToolSelected(this.currentlySelectedTool, false);
        this.toggleToolSelected(toolName, true);
        this.currentlySelectedTool = toolName;
        window.dispatchEvent(event);
    }

    onButtonSelected(buttonName: string)
    {
        const event = new CustomEvent(`${buttonName}_button:pressed`, {
            detail: {
            }
        });
        window.dispatchEvent(event);
    }

    initializeTool(toolName: string)
    {
        this.buttons[toolName] = document.getElementById(`${toolName}Button`)!;
        this.buttons[toolName].addEventListener("mousedown", (_) => this.onToolSelected(toolName));
        this.buttons[toolName].addEventListener("mouseover", (_) => this.onMouseHover(toolName, true));
        this.buttons[toolName].addEventListener("mouseout", (_) => this.onMouseHover(toolName, false));
        this.toggleToolSelected(toolName, false);
    }

    initializeButton(buttonName: string)
    {
        this.buttons[buttonName] = document.getElementById(`${buttonName}Button`)!;
        this.buttons[buttonName].addEventListener("mousedown", (_) => this.onButtonSelected(buttonName));
        this.buttons[buttonName].addEventListener("mouseover", (_) => this.onMouseHover(buttonName, true));
        this.buttons[buttonName].addEventListener("mouseout", (_) => this.onMouseHover(buttonName, false));
    }

    currentTool()
    {
        return this.currentlySelectedTool;
    }

    enableHomeButton(enabled: boolean)
    {
        this.buttons["home"].style.display = enabled ? 'block' : 'none';
    }

    constructor()
    {
        this.buttons = {};
        this.currentlySelectedTool = "cursor";

        this.initializeTool("cursor");
        this.initializeTool("pencil");
        this.initializeTool("sticky");
        this.initializeTool("text");
        this.initializeButton("home");
        this.initializeButton("download");

        this.onToolSelected("cursor");
        this.enableHomeButton(false);
    }
}

export default ToolBarManager;