import { fabric } from "fabric";
import { localContext } from "./local_context";
import { IEntity } from "./entity";

export class ImageUpload implements IEntity {
    #image : fabric.Image | undefined;
    #url: string = "";
    #index: number = 0;

    initialize(x: number, y: number, scaleX: number, scaleY: number, angle: number, url: string)
    {
        this.#url = url;
        let jsImage = new Image();
        jsImage.crossOrigin = "Anonymous";
        jsImage.onload = () => {
            this.#image = new fabric.Image(jsImage, {
                left: x,
                top: y,
                originX: "center",
                originY: "center",
                scaleX: scaleX,
                scaleY: scaleY,
                angle: angle,
                data: this,
            });

            this.#image!.perPixelTargetFind = true;
            this.onLoaded();
        }
        if (url.startsWith("http"))
        {
            jsImage.src = url + "?not-from-cache-please";
        }
        else
        {
            jsImage.src = url;
        }
    }

    constructor(x: number, y: number, scaleX: number = 0.3, scaleY: number = 0.3, angle: number = 0, url: string | null = null)
    {
        if (url !== null)
        {
            this.initialize(x, y, scaleX, scaleY, angle, url);
        }
    }

    get index(): number {
        return this.#index;
    }

    get object(): fabric.Object | undefined {
        return this.#image;
    }

    onLoaded = (): void => {};

    serialize(): {} | undefined{
        if (this.#image === undefined)
        {
            return undefined;
        }

        return {
            x: this.#image!.left,
            y: this.#image!.top,
            scaleX: this.#image!.scaleX,
            scaleY: this.#image!.scaleY,
            width: this.#image!.width,
            height: this.#image!.height,
            angle: this.#image!.angle,
            url: this.#url,
            index: localContext.activity.canvas.getObjects().indexOf(this.#image),
            type: "image"
        };
    }

    deserialize(object: {x: number, y: number, index: number, scaleX: number, scaleY: number, width: number, height: number, angle: number, url: string, type: string}): void {
        this.#index = object.index;
        this.initialize(object.x, object.y, object.scaleX, object.scaleY, object.angle, object.url);
        this.#image?.set('width', object.width);
        this.#image?.set('height', object.height);
    }

    lock(isLocked: boolean): void {
        this.#image!.lockMovementX = isLocked;
        this.#image!.lockMovementY = isLocked;
        this.#image!.lockRotation = isLocked;
    }
}