class ColorBarManager
{
    colorButtons: {[key: string] : HTMLElement} = {};
    colorBar : HTMLElement;
    colorButton : HTMLElement;
    colorCircleSelectedArrow : HTMLElement;

    onMouseHover(colorHex: string, isEntering: boolean)
    {
        this.colorButtons[colorHex].style.background = isEntering ? `#${colorHex}66` : `#${colorHex}`;
    }


    onButtonPressed(colorHex: string)
    {
        const event = new CustomEvent(`side_bar:change_color`, {
            detail: {
                colorHex: `#${colorHex}`,
            }
        });
        window.dispatchEvent(event);
    }

    enable(enable: boolean)
    {
        this.colorBar.style.display = enable ? 'grid' : 'none';
        this.colorButton.style.borderColor = enable ? "#FFC145" : "#E1E4EB";
        this.colorCircleSelectedArrow.style.display = enable ? 'block' : 'none';
    }

    initializeButton(colorHex: string, row: number, col: number)
    {
        const button = document.createElement(`colorOption${colorHex}`);
        this.colorBar.appendChild(button);
        button.className = "colorCircle";
        button.style.gridColumnStart = `${col}`;
        button.style.gridColumnStart = `${row}`;
        button.style.backgroundColor = `#${colorHex}`;
        this.colorButtons[colorHex] = button;
        button.addEventListener("mousedown", (_) => this.onButtonPressed(colorHex));
        button.addEventListener("mouseover", (_) => this.onMouseHover(colorHex, true));
        button.addEventListener("mouseout", (_) => this.onMouseHover(colorHex, false));
    }
    
    constructor()
    {
        this.colorButtons = {};
        this.colorBar = document.getElementById('colorBar')!;
        this.colorCircleSelectedArrow = document.getElementById('colorCircleSelectedArrow')!;
        this.colorButton = document.getElementById('colorButton')!;

        this.initializeButton("FFFFFF", 1, 1);
        this.initializeButton("E197C0", 2, 1);
        this.initializeButton("979797", 1, 2);
        this.initializeButton("B03229", 2, 2);
        this.initializeButton("000000", 1, 3);
        this.initializeButton("725246", 2, 3);
        this.initializeButton("7BAC7A", 1, 4);
        this.initializeButton("4EAFCE", 2, 4);
        this.initializeButton("F5DC71", 1, 5);
        this.initializeButton("3D6BA7", 2, 5);
        this.initializeButton("E9A04D", 1, 6);
        this.initializeButton("9871CF", 2, 6);
    }
}

export default ColorBarManager;