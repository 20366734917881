class TopBarManager {
    buttons: { [key: string]: HTMLElement } = {};
    slideCountLabel: HTMLElement;
    deleteSlideButton: HTMLElement;

    onMouseHover(direction: string, isEntering: boolean) {
        this.buttons[direction].style.background = isEntering ? "#F8F8F8" : "#FFFFFF";
    }

    onButtonPressed(direction: string) {
        const event = new CustomEvent("change_slide", {
            detail: {
                direction: direction,
            },
        });
        window.dispatchEvent(event);
    }

    setSlideNumber(slideNumber: number, maxNumSlides: number) {
        this.slideCountLabel.textContent = `${slideNumber + 1} / ${maxNumSlides}`;
    }

    initializeButton(direction: string) {
        this.buttons[direction] = document.getElementById(`${direction}Button`)!;
        this.buttons[direction].addEventListener("mousedown", (_) =>
            this.onButtonPressed(direction)
        );
        this.buttons[direction].addEventListener("mouseover", (_) =>
            this.onMouseHover(direction, true)
        );
        this.buttons[direction].addEventListener("mouseout", (_) =>
            this.onMouseHover(direction, false)
        );
    }

    constructor() {
        this.buttons = {};
        this.slideCountLabel = document.getElementById("slideCountLabel")!;

        this.initializeButton("left");
        this.initializeButton("right");

        this.deleteSlideButton = document.getElementById("deleteSlideButton")!;
        this.deleteSlideButton.addEventListener("mousedown", (_) => {
            const event = new CustomEvent("delete_slide", {
                detail: {
                },
            });
            window.dispatchEvent(event);
        });
    }
}

export default TopBarManager;
