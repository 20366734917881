class ContextualMenu {
    menu: HTMLElement;
    buttons: {[key: string] : HTMLElement} = {};

    onButtonPressed(buttonName: string, mousePos: {x: number, y: number}) {
        const event = new CustomEvent(`contextual_menu:${buttonName}`, {
            detail: {
                mousePos
            },
        });
        window.dispatchEvent(event);
    }

    initializeButton(buttonName: string)
    {
        this.buttons[buttonName] = document.getElementById(`${buttonName}Button`)!;
        this.buttons[buttonName].addEventListener("mousedown", (event: MouseEvent) => {
            this.onButtonPressed(buttonName, { x: event.clientX, y: event.clientY });
        });
    }

    showMenu(show: boolean)
    {
        this.menu.style.display = show ? "grid" : "none";
    }

    setMenuPosition(x: number, y: number)
    {
        this.menu.style.left = `${x}px`;
        this.menu.style.top = `${y}px`
    }

    constructor()
    {
        this.menu = document.getElementById("contextualMenu")!;
        this.initializeButton("copy");
        this.initializeButton("paste");
        this.initializeButton("sendToBack");
        this.initializeButton("sendToFront");
        this.showMenu(false);
    }

}

export default ContextualMenu;