import ColorBarManager from "./colorBar";

class SideBarManager
{
    buttons: {[key: string] : HTMLElement} = {};
    sideBar : HTMLElement;
    colorBarManager : ColorBarManager;

    typeToOptions : {[key: string] : string[]} = {
        "group": ["color", "delete"],
        "stickyRect": ["color", "delete"],
        "path": ["color", "delete"],
        "image": ["delete"],
    }

    onMouseHover(toolName: string, isEntering: boolean)
    {
        this.buttons[toolName].style.background = isEntering ? "#F8F8F8" : "#FFFFFF";
    }

    onButtonPressed(buttonName: string)
    {
        const event = new CustomEvent(`side_bar:${buttonName}`, {
            detail: {
                toolName: buttonName,
            }
        });
        window.dispatchEvent(event);
        this.colorBarManager.enable(buttonName === "color");
    }

    initializeButton(buttonName: string)
    {
        this.buttons[buttonName] = document.getElementById(`${buttonName}Button`)!;
        this.buttons[buttonName].addEventListener("mousedown", (_) => this.onButtonPressed(buttonName));
        this.buttons[buttonName].addEventListener("mouseover", (_) => this.onMouseHover(buttonName, true));
        this.buttons[buttonName].addEventListener("mouseout", (_) => this.onMouseHover(buttonName, false));
    }

    enableForType(enable: boolean, type: string)
    {
        if (this.typeToOptions[type] === undefined)
        {
            this.enable(enable);
        }
        else
        {
            this.enable(enable, this.typeToOptions[type]);
        }
    }
    
    enable(enable: boolean,
           showOptions: string[] = ["color", "delete"])
    {
        this.sideBar.style.display = enable ? 'grid' : 'none';
        if (enable == false)
        {
            this.colorBarManager.enable(false);
        }

        Object.values(this.buttons).forEach(element => {
            (element as HTMLElement).style.display = 'none';
        })

        showOptions.forEach((option) => {
            if (this.buttons[option] !== undefined)
            {
                this.buttons[option].style.display = 'grid';
            }
        });
    }

    constructor()
    {
        this.buttons = {};
        this.colorBarManager = new ColorBarManager();
        this.sideBar = document.getElementById('sideBar')!;

        this.initializeButton("color");
        this.initializeButton("lock");
        this.initializeButton("delete");
        this.enable(false);

        window.addEventListener("object:selected", (event: CustomEventInit) => {
            this.enableForType(true, event.detail.objectType);
        });
        window.addEventListener("object:unselected", (_: CustomEventInit) => {
            this.enable(false);
        });
    }
}

export default SideBarManager;