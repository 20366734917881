function preventDefault(e: Event) {
    e.preventDefault();
    e.stopPropagation();
}

class DragAndDropManager
{
    dropRegion: HTMLElement;
    validateImage(image: Blob) {
        // check the type
        var validTypes = ['image/jpeg', 'image/png', 'image/gif'];
        if (validTypes.indexOf( image.type ) === -1) {
            return false;
        }
     
        // check the size
        var maxSizeInBytes = 5e6; // 5MB
        if (image.size > maxSizeInBytes) {
            return false;
        }
     
        return true;
    }

    validateJson(json: Blob)
    {
        var validTypes = ['application/json'];
        return (validTypes.indexOf( json.type ) !== -1)
     }

    uploadImageFromURL(url: string, mouseX: number = 0, mouseY: number = 0) {
        const event = new CustomEvent('load_url_image', {
            detail: {
                mouseX: mouseX,
                mouseY: mouseY,
                url: url
            }
        });

        window.dispatchEvent(event);
    }

    handleFiles(files: Blob[], mouseX: number, mouseY: number)
    {
        for (var i = 0, len = files.length; i < len; i++) {
            if (this.validateImage(files[i]))
            {
                // read the image...
                var reader = new FileReader();
                reader.onload = function (e) {
                    const event = new CustomEvent("load_url_image", {
                        detail: {
                            mouseX: mouseX,
                            mouseY: mouseY,
                            url: e.target!.result,
                        },
                    });
                    window.dispatchEvent(event);
                };
                reader.readAsDataURL(files[i]);
            }
            else if (this.validateJson(files[i]))
            {
                // read the image...
                var reader = new FileReader();
                reader.onload = function (e) {
                    const event = new CustomEvent("load_activity_json", {
                        detail: {
                            data: e.target!.result! as string,
                        },
                    });
                    window.dispatchEvent(event);
                };
                reader.readAsText(files[i]);
            }
        }
    }

    handleDrop(event: any)
    {
        console.log("Received drop event");
        event.preventDefault();
        event.stopPropagation();
        const data = event.dataTransfer;
        const files = data.files;

        if (files.length) {
            this.handleFiles(files, event.clientX, event.clientY);
        } else {

            // check for img
            const html = data.getData('text/html');
            const match = html && /\bsrc="?([^"\s]+)"?\s*/.exec(html);
            const url = match && match[1];

            if (url) {
                this.uploadImageFromURL(url);
                return;
            }
        }
    }

    constructor(dropRegion: HTMLElement)
    {
        this.dropRegion = dropRegion;
        dropRegion.addEventListener('dragenter', preventDefault, false);
        dropRegion.addEventListener('dragleave', preventDefault, false);
        dropRegion.addEventListener('dragover', preventDefault, false);
        dropRegion.addEventListener('drop', this.handleDrop.bind(this), false);
    }
}

export default DragAndDropManager;