import { IEntity, translateEntity } from "./entity";
import { fabric } from "fabric";
import { localContext } from "./local_context";
import { StickyNote } from "./sticky_note";
import { TextBox } from "./text_box";
import { Drawing } from "./drawing";
import { ImageUpload } from "./image";
import { bool } from "aws-sdk/clients/signer";

export class Activity {
    #entities: Set<IEntity> = new Set();
    #canvas: fabric.Canvas;
    #slides: string[] = [];
    #currentSlide: number = 0;
    #version = "v01";
    #isLoading = false;

    get canvas() {
        return this.#canvas;
    }

    onWindowResize() {
        this.#canvas.setWidth(window.innerWidth);
        this.#canvas.setHeight(window.innerHeight);
    }

    onObjectSelected(target: fabric.Object) {
        const event = new CustomEvent(`object:selected`, {
            detail: {
                objectType: target.type,
            },
        });
        window.dispatchEvent(event);
    }

    onSelectionCleared() {
        const event = new CustomEvent(`object:unselected`, {
            detail: {},
        });
        window.dispatchEvent(event);
    }

    addEntity(entity: IEntity, addToCanvas: boolean = true) {
        this.#entities.add(entity);
        if (entity.object !== undefined && addToCanvas) {
            this.#canvas.add(entity.object);
        }
    }

    removeEntity(entity: IEntity) {
        this.#entities.delete(entity);
    }

    serializeObjects(objects: fabric.Object[]) {
        let serialized: any[] = [];
        for (let index = 0; index < objects.length; ++index) {
            if (objects[index].data) {
                const entity = objects[index].data as IEntity;
                if (!entity) {
                    continue;
                }
                serialized.push(entity.serialize());
            }
        }

        // We do this because otherwise copy pasting with the mouse position as the offset doesn't work for individual objects
        // When group selecting an object, all objects inside the group get their positions centered around (0, 0)
        if (serialized.length == 1)
        {
            serialized[0].x = 0;
            serialized[0].y = 0;
        }

        return JSON.stringify(serialized);
    }

    serializeCurrentSlide() {
        return this.serializeObjects(this.#canvas.getObjects());
    }

    loadEntitiesFromJson(
        json: string,
        positionOffset: { x: number; y: number } = { x: 0, y: 0 },
        fitToContent: boolean = true
    ) {
        this.#isLoading = true;

        try {
            let serialized: any[] = JSON.parse(json);
            let serializedImages: any[] = [];
            let imageEntities: ImageUpload[] = [];

            serialized.forEach((entity) => {
                if (entity.type === "sticky_note") {
                    let newSticky = new StickyNote(0, 0, null, "");
                    newSticky.deserialize(entity);
                    translateEntity(newSticky, positionOffset);
                    this.addEntity(newSticky);
                } else if (entity.type === "image") {
                    serializedImages.push(entity);
                } else if (entity.type === "text_box") {
                    let newTextBox = new TextBox(0, 0, null, "");
                    newTextBox.deserialize(entity);
                    translateEntity(newTextBox, positionOffset);
                    this.addEntity(newTextBox);
                } else if (entity.type === "drawing") {
                    let drawing = new Drawing();
                    drawing.deserialize(entity);
                    translateEntity(drawing, positionOffset);
                }
            });
            this.#isLoading = serializedImages.length !== 0;
            serializedImages.forEach((entity) => {
                let image = new ImageUpload(0, 0);
                image.onLoaded = () => {
                    translateEntity(image, positionOffset);
                    imageEntities.push(image);
                    if (imageEntities.length == serializedImages.length)
                    {
                        imageEntities.forEach((im) => {
                            this.addEntity(im)
                        });
                        imageEntities.forEach((im) => {
                            this.#canvas.moveTo(im.object!, im.index);
                        });
                        if (fitToContent) {
                            localContext.camera.fitToContent();
                        }
                        this.#isLoading = false;
                    }
                };
                image.deserialize(entity);
            });
        } catch (e: any) {
            this.#isLoading = false;
            console.error(`ERROR: ${JSON.stringify(e)}`);
        }
    }

    store() {
        if (!this.#isLoading) {
            this.#slides[this.#currentSlide] = this.serializeCurrentSlide();
            localStorage.setItem("koalaProtoActivity", JSON.stringify(this.#slides));
            localStorage.setItem("koalaProtoVersion", this.#version);
        }
    }

    loadFromStore() {
        let version = localStorage.getItem("koalaProtoVersion")?.replace(/["]+/g, "");
        if (version === null || version !== this.#version) {
            console.log(
                `Trying to load activity with incorrect version, got ${version} need ${
                    this.#version
                }`
            );
            return;
        }
        let jsonValue = localStorage.getItem("koalaProtoActivity");
        if (jsonValue !== null) {
            this.#slides = JSON.parse(jsonValue);
            this.loadSlideNumber(0);
        }
    }

    loadSlideNumber(slide: number) {
        this.#currentSlide = slide;

        while (slide > this.#slides.length - 1) {
            this.#slides.push("{}");
        }

        this.#entities.clear();
        this.#canvas.clear();
        this.loadEntitiesFromJson(this.#slides[slide]);

        localContext.topBarManager.setSlideNumber(this.#currentSlide, this.#slides.length);
        localContext.camera.fitToContent();
    }

    deleteCurrentSlide() {
        if (this.#slides.length > 1) {
            this.#slides.splice(this.#currentSlide);
            let newSlide = Math.max(this.#currentSlide - 1, 0);
            this.loadSlideNumber(newSlide);
            this.store();
        }
    }

    downloadToFile() {
        const content = JSON.stringify(this.#slides);
        var a = document.createElement("a");
        var file = new Blob([content], { type: "application/json" });
        a.href = URL.createObjectURL(file);
        a.download = "koala_export.json";
        a.click();
        a.remove();
    }

    constructor() {
        this.#slides.push("{}");
        this.#currentSlide = 0;

        this.#canvas = new fabric.Canvas("MainCanvas", {
            fireMiddleClick: true,
            fireRightClick: true,
            targetFindTolerance: 2,
            preserveObjectStacking: true
        });

        window.onresize = this.onWindowResize.bind(this);
        document.addEventListener("contextmenu", (event) => event.preventDefault());

        this.#canvas.on("selection:created", (e) => {
            if (e.target == undefined) {
                return;
            }
            const activeSelection = e.target!;
            this.onObjectSelected(e.target);
            if (activeSelection.type === "activeSelection") {
                activeSelection.borderScaleFactor = 3;
                activeSelection.padding = 4;
                activeSelection.hasControls = true;
                activeSelection.lockRotation = true;
                activeSelection.setControlsVisibility({
                    mt: false,
                    mb: false,
                    mr: false,
                    ml: false,
                    mtr: false,
                });
            }
        });
        this.#canvas.on("selection:updated", (e) => {
            if (e.target == undefined) {
                return;
            }
            this.onObjectSelected(e.target!);
        });

        this.#canvas.on("selection:cleared", (_) => {
            this.onSelectionCleared();
        });

        this.#canvas.on("object:added", () => {
            this.store();
        });

        this.#canvas.on("object:modified", () => {
            this.store();
        });

        this.#canvas.on("selection:modified", () => {
            this.store();
        });

        this.#canvas.on("path:created", (event: any) => {
            let drawing = new Drawing(event.path as fabric.Path);
            this.addEntity(drawing, false);
            this.store();
        });

        this.onWindowResize();

        this.#canvas.backgroundColor = "#F3F3F3";

        window.addEventListener("change_slide", (event: CustomEventInit) => {
            let direction: string = event.detail.direction;
            let slideNumber = this.#currentSlide + (direction === "left" ? -1 : 1);
            if (slideNumber < 0) {
                slideNumber = 0;
            }

            this.#slides[this.#currentSlide] = this.serializeCurrentSlide();
            this.store();
            this.loadSlideNumber(slideNumber);
        });

        window.addEventListener("delete_slide", (_) => {
            this.deleteCurrentSlide();
        });

        window.addEventListener("download_button:pressed", (_) => {
            this.downloadToFile();
        });
        window.addEventListener("load_activity_json", (event: CustomEventInit) => {
            try {
                this.#slides = JSON.parse(event.detail.data);
                this.loadSlideNumber(0);
            } catch (_) {
                console.error("failed to load json");
            }
        });
    }
}
