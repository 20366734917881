import { fabric } from "fabric";

export enum CameraPanState {
    NotDragging = 0,
    TryingToDrag,
    Dragging,
}

export class Camera {
    cameraPanState: CameraPanState = CameraPanState.NotDragging;

    #canvas: fabric.Canvas;
    #lastPosX: number = 0;
    #lastPosY: number = 0;
    #viewTransform: number[] = [];
    #draggingThreshold = 10;

    constructor(canvas: fabric.Canvas) {
        this.#canvas = canvas;
    }

    fitToContent(paddingCoef: number = 1.3, offsetCoefs = { x: 0, y: -0.02 }) {
        if (this.#canvas.getObjects().length === 0) {
            return;
        }
        var group = new fabric.Group(this.#canvas.getObjects());

        let transform = [...this.#canvas.viewportTransform!];

        let scale = Math.min(
            this.#canvas.width! / (group.width! * paddingCoef),
            this.#canvas.height! / (group.height! * paddingCoef)
        );
        scale = Math.min(Math.max(scale, 0.1), 3);

        transform[4] =
            (this.#canvas.width! - group.width!) * 0.5 - group.left! + offsetCoefs.x * group.width!;
        transform[5] =
            (this.#canvas.height! - group.height!) * 0.5 -
            group.top! +
            offsetCoefs.y * group.height!;
        transform[0] = 1;
        transform[3] = 1;

        group.destroy();

        this.#canvas.setViewportTransform(transform);
        this.#canvas.zoomToPoint(
            { x: this.#canvas.width! * 0.5, y: this.#canvas.height! * 0.5 },
            scale
        );
    }

    screenToCanvasPos(x: number, y: number) {
        const transform = this.#canvas.viewportTransform;
        const invertedScaleX = 1 / transform![0];
        const invertedScaleY = 1 / transform![3];

        const transformedX = invertedScaleX * x - invertedScaleX * transform![4];
        const transformedY = invertedScaleY * y - invertedScaleY * transform![5];

        return { x: transformedX, y: transformedY };
    }

    canvasToScreenPos(x: number, y: number) {
        const transform = this.#canvas.viewportTransform;

        const scaleX = transform![0];
        const scaleY = transform![3];

        const screenX = scaleX * x + transform![4];
        const screenY = scaleY * y + transform![5];

        return { x: screenX, y: screenY };
    }

    startPan(posX: number, posY: number) {
        this.cameraPanState = CameraPanState.TryingToDrag;
        this.#lastPosX = posX;
        this.#lastPosY = posY;
        this.#canvas.setCursor("grabbing");
        this.#canvas.selection = false;
        this.#viewTransform = [...this.#canvas.viewportTransform!];
    }

    pan(posX: number, posY: number) {
        if (this.cameraPanState === CameraPanState.TryingToDrag) {
            const diff = Math.pow(posX - this.#lastPosX, 2) + Math.pow(posY - this.#lastPosY, 2);
            if (diff > this.#draggingThreshold) {
                this.cameraPanState = CameraPanState.Dragging;
            }
        }
        if (this.cameraPanState === CameraPanState.Dragging) {
            this.#viewTransform[4] += posX - this.#lastPosX;
            this.#viewTransform[5] += posY - this.#lastPosY;
            this.#canvas.setViewportTransform(this.#viewTransform);
            this.#canvas.requestRenderAll();
            this.#canvas.setCursor("grabbing");
            this.#lastPosX = posX;
            this.#lastPosY = posY;
        }
    }

    stopPan() {
        if (this.cameraPanState !== CameraPanState.NotDragging) {
            this.cameraPanState = CameraPanState.NotDragging;
            this.#canvas.setViewportTransform(this.#viewTransform);
            this.#canvas.setCursor("default");
            this.#canvas.selection = true;
        }
    }

    zoom(delta: number, centerX: number, centerY: number) {
        var zoom = this.#canvas.getZoom();
        zoom *= 0.999 ** delta;
        if (zoom > 20) zoom = 20;
        if (zoom < 0.01) zoom = 0.01;
        this.#canvas.zoomToPoint({ x: centerX, y: centerY }, zoom);
    }
}
