import { fabric } from 'fabric'
import FontFaceObserver from 'fontfaceobserver';
import { IEntity } from './entity';
import { currentlyEditingEntity, setCurrentlyEditingEntity, ITextEntity } from './text_entity';

export class TextBox implements IEntity, ITextEntity {
    #textBox : fabric.Textbox | undefined;

    initialize(x: number = 0, y: number = 0, text: string = "Double click", color: string = "#000000")
    {
         this.#textBox = new fabric.Textbox(text, {
            fontFamily: 'Comic Sans MS',
            left: x,
            top: y,
            width: 100,
            fontSize: 40,
            textAlign: "left",
            hasControls: true,
            hasRotatingPoint: false,
            fill: color,
            originX: "center",
            originY: "center",
            splitByGrapheme: false,
            data: this
        });

        let fontFace = new FontFaceObserver("Short Stack");
        fontFace.load().then(() => {
            this.#textBox!.set("fontFamily", "Short Stack");

        }).catch((exception) => {
            console.error(exception);
        })

    }

    serialize(): {} {
        const xPos = this.#textBox!.left;
        const yPos = this.#textBox!.top;
        return {
            x: xPos,
            y: yPos,
            scaleX: this.#textBox!.scaleX,
            scaleY: this.#textBox!.scaleY,
            width: this.#textBox!.width,
            height: this.#textBox!.height,
            angle: this.#textBox!.angle,
            color: this.#textBox!.fill,
            text: this.#textBox!.text,
            type: "text_box",
        };
    }

    enterEditing()
    {
        this.#textBox!.enterEditing();
        setCurrentlyEditingEntity(this);
    }

    stopEditing(): void {
        this.#textBox!.exitEditing();
        if (currentlyEditingEntity !== undefined && currentlyEditingEntity === this)
        {
            setCurrentlyEditingEntity(undefined);
        }
    }

    get textBox(): fabric.Textbox {
        return this.#textBox!;
    }

    deserialize(object: { x: number; y: number; angle: number; scaleX: number; scaleY: number; width: number; height: number; text: string; color: string }): void {
        this.initialize(object.x, object.y, object.text, object.color);
        this.#textBox!.set('scaleX', object.scaleX);
        this.#textBox!.set('scaleY', object.scaleY);
        this.#textBox!.set('width', object.width);
        this.#textBox!.set('height', object.height);
        this.#textBox!.set('angle', object.angle);
    }


    constructor(x: number = 0, y: number = 0, text: string | null = null, color: string = "#000000")
    {
        if (text === null) {
            return;
        }

        this.initialize(x, y, text, color);
    }

    get object(): fabric.Object | undefined {
        return this.#textBox;
    }

    lock(isLocked: boolean): void {
        this.stopEditing();
        this.#textBox!.lockMovementX = isLocked;
        this.#textBox!.lockMovementY = isLocked;
        this.#textBox!.lockRotation = isLocked;
    }
}