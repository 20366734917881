import { fabric } from "fabric";
import { localContext } from "./local_context";
import { IEntity } from "./entity";

export class Drawing implements IEntity {
    #path: fabric.Path | undefined;

    get object(): fabric.Object | undefined {
        return this.#path;
    }

    serialize(): {} | undefined {
        const serialized = {
            path: this.#path!.toObject(),
            type: "drawing",
        };
        return serialized;
    }

    deserialize(object: { path: object; type: string }): void {
        fabric.Path.fromObject(object.path, (path: any) => {
            this.#path = path;
            this.initialize();
            localContext.activity.addEntity(this);
        });
    }

    initialize() {
        this.#path!.data = this;
    }

    constructor(path: fabric.Path | undefined = undefined) {
        if (path !== undefined) {
            this.#path = path;
            this.initialize();
        }
    }

    lock(isLocked: boolean): void {
        this.#path!.lockMovementX = isLocked;
        this.#path!.lockMovementY = isLocked;
        this.#path!.lockRotation = isLocked;
    }
}
