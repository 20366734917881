import { fabric } from 'fabric'
import { localContext } from './local_context';
import FontFaceObserver from 'fontfaceobserver';
import { IRectOptions } from 'fabric/fabric-impl';
import { IEntity } from './entity';
import { currentlyEditingEntity, ITextEntity, setCurrentlyEditingEntity } from './text_entity';

interface IStickyRectOptions extends IRectOptions {
    stickyNote: StickyNote;
}

export class StickyRect extends fabric.Rect implements IStickyRectOptions {
    public readonly stickyNote: StickyNote;
    public constructor(options: IStickyRectOptions)
    {
        super(options);
        this.set("type", "stickyRect")
        this.stickyNote = options.stickyNote;
    }
}

export class StickyNote implements IEntity, ITextEntity {
    #textBox : fabric.Textbox | undefined;
    #square : StickyRect | undefined;
    #group : fabric.Group | undefined;
    #canvas : fabric.Canvas | undefined;
    #fixedTextBoxWidth : number = 80;

    initialize(x: number = 0, y: number = 0, text: string = "Double click", color: string = "#7BAC7A")
    {
        this.#canvas = localContext.activity.canvas;
        this.#fixedTextBoxWidth = 80;
        this.#square = new StickyRect({
            left: x,
            top: y,
            fill: color,
            width: 100,
            height: 100,
            rx: 10,
            ry: 10,
            lockRotation: true,
            lockScalingX: true,
            lockScalingY: true,
            lockMovementX: true,
            lockMovementY: true,
            selectable: false,
            hoverCursor: "arrow",
            hasControls: false,
            padding: 3,
            borderScaleFactor: 3,
            originX: "center",
            originY: "center",
            stickyNote: this,
        })

        this.#textBox = new fabric.Textbox(text, {
            fontFamily: 'Comic Sans MS',
            left: x,
            top: y,
            width: 80,
            fontSize: 10,
            textAlign: "center",
            hasControls: false,
            lockMovementX: true,
            lockMovementY: true,
            fill: '#FFFFFF',
            originX: "center",
            originY: "center",
            splitByGrapheme: false,
        });

        let fontFace = new FontFaceObserver("Short Stack");
        fontFace.load().then(() => {
            this.#textBox!.set("fontFamily", "Short Stack");

        }).catch((exception) => {
            console.error(exception);
        })


        this.#textBox.on('changed', (_) => {
            this.onTextChanged();
        })

        this.createGroup();
        this.updateTextColor();
        this.onTextChanged();
        this.onTextChanged();
    }

    constructor(x: number, y: number, text: string | null, color: string = "#7BAC7A")
    {
        if (text !== null)
        {
            this.initialize(x, y, text!, color)
        }
    }

    get textBox()
    {
        return this.#textBox!;
    }

    updateTextColor() {
        let bgColorCode: string = this.#square!.get("fill") as string;
        const hexNumber = '0x' + bgColorCode.slice(1)
        const integerRepresentation = parseInt(hexNumber, 16)
        if (bgColorCode.length === 7)
        {
            this.#textBox!.set('fill', integerRepresentation > 8388607 ? '#000000' : '#ffffff');
        }
        else if (bgColorCode.length === 4)
        {
            this.#textBox!.set('fill', integerRepresentation > 2047 ? '#000000' : '#ffffff');
        }
        else
        {
            console.error('Unexpected colorCode length of ' + bgColorCode.length + '! ' + bgColorCode)
        }
    }
    
    setColor(hexColor: string)
    {
        this.#square!.set("fill", hexColor);
        this.updateTextColor();
        localContext.activity.store();
    }

    get color()
    {
        return this.#square!.backgroundColor;
    }

    calcLineWidth(lineIndex: number)
    {
        let width: number = 0;
        let line = this.#textBox!._textLines[lineIndex];
        if (line.length === 0) {
            width = 0;
        }
        else {
            const lineInfo = this.#textBox!.measureLine(lineIndex);
            width = lineInfo.width;
        }
        return width;
    }

    calcLineHeight(lineIndex: number)
    {
        var line = this.#textBox!._textLines[lineIndex],
            maxHeight = this.#textBox!.getHeightOfChar(lineIndex, 0);
        for (var i = 1, len = line.length; i < len; i++) {
            maxHeight = Math.max(this.#textBox!.getHeightOfChar(lineIndex, i), maxHeight);
        }
        return maxHeight * this.#textBox!.lineHeight! * this.#textBox!._fontSizeMult;
    }

    calcTextWidth() {
      var maxWidth = this.calcLineWidth(0);
      for (var i = 1, len = this.#textBox!._textLines.length; i < len; i++) {
        var currentLineWidth = this.calcLineWidth(i);
        if (currentLineWidth > maxWidth) {
          maxWidth = currentLineWidth;
        }
      }
      return maxWidth;
    }

    calcTextHeight() {
        var lineHeight = 0, height = 0;
        for (var i = 0, len = this.#textBox!._textLines.length; i < len; i++) {
            lineHeight = this.calcLineHeight(i);
            height += (i === len - 1 ? lineHeight / this.#textBox!.lineHeight! : lineHeight);
        }
        return height;
    }

    onTextChanged()
    {
        let min = 1;
        let max = this.#fixedTextBoxWidth;
        const maxIterations = 15;
        let numIterations = 0;
        let textWidth = 0;
        let textHeight = 0;
        const maxSize = this.#fixedTextBoxWidth;
        const minSize = this.#fixedTextBoxWidth * 0.95;

        while (min < max && numIterations < maxIterations)
        {
            let mid = (max + min) * 0.5;
            this.#textBox!.set('fontSize', mid);
            this.#textBox!.width = this.#fixedTextBoxWidth;
            textWidth = this.calcTextWidth();
            textHeight = this.calcTextHeight();

            if (textWidth > maxSize || textHeight > maxSize)
            {
                max = mid;
            }
            else if (textWidth < minSize || textHeight < minSize)
            {
                min = mid;
            }
            else
            {
                return;
            }
            numIterations++;
        }
    }

    enterEditing()
    {
        this.#group!.destroy();
        this.#canvas!.add(this.#square!);
        this.#canvas!.add(this.#textBox!);
        this.#canvas!.remove(this.#group!);
        this.#textBox!.enterEditing();
        this.#textBox!.selectAll();
        this.#group = undefined;
        setCurrentlyEditingEntity(this);
    }

    stopEditing()
    {
        this.#textBox!.exitEditing();
        this.#canvas!.remove(this.#square!);
        this.#canvas!.remove(this.#textBox!);

        this.createGroup();
        this.#canvas!.add(this.#group!);

        if (currentlyEditingEntity === this)
        {
            setCurrentlyEditingEntity(undefined);
        }
    }

    get object(): fabric.Object | undefined {
        return this.#group;
    }

    createGroup()
    {
        this.#group = new fabric.Group([this.#square!, this.#textBox!], {
            lockRotation: true,
            borderScaleFactor: 3,
            padding: 4,
            originX: 'center',
            originY: 'center',
            data: this
        });

        this.#group.setControlsVisibility({
            mt: false,
            mb: false,
            mr: false,
            ml: false,
            mtr: false,
        });

        this.#group.on("mousedblclick", (_) => {
            this.enterEditing();
        });

        this.#group.on("touch", (_) => {
            this.enterEditing();
        });

    }

    serialize(): {} {
        const xPos = currentlyEditingEntity === this ? this.#square!.left : this.#group!.left;
        const yPos = currentlyEditingEntity === this ? this.#square!.top : this.#group!.top;
        return {
            x: xPos,
            y: yPos,
            color: this.#square!.fill,
            text: this.#textBox!.text,
            type: "sticky_note",
            fontSize: this.#textBox!.fontSize,
            scale: this.#group ? this.#group?.scaleX : this.#square?.scaleX
        };
    }

    deserialize(object: {x: number, y: number, fontSize: number, scale: number, text: string, color: string}): void {
        this.initialize(object.x, object.y, object.text, object.color);
        this.#group?.set("scaleX", object.scale);
        this.#group?.set("scaleY", object.scale);
        this.#textBox?.set("fontSize", object.fontSize)
    }

    lock(isLocked: boolean): void {
        this.stopEditing();
        this.#group!.lockMovementX = isLocked;
        this.#group!.lockMovementY = isLocked;
        this.#group!.lockRotation = isLocked;
    }
}