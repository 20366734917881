import ToolBarManager from "../ui/toolbar";
import { fabric } from "fabric";
import { StickyNote, StickyRect } from "./sticky_note";
import { Queue } from "../helpers/queue";
import { localContext } from "./local_context";
import { ImageUpload } from "./image";
import { TextBox } from "./text_box";
import { currentlyEditingEntity } from "./text_entity";
import { IEntity } from "./entity";
import { CameraPanState } from "./camera";

class ControlsManager {
    #toolToCursor: { [key: string]: string } = {};
    #canvas: fabric.Canvas;
    #toolBarManager: ToolBarManager;
    #clipBoard: string = "[]";

    onToolChanged(toolName: string) {
        this.#canvas.setCursor(this.#toolToCursor[toolName]);

        this.#canvas.isDrawingMode = toolName === "pencil";
        this.#canvas.freeDrawingBrush.width = 3;
    }

    initMouseKeyboardEvents() {
        fabric.IText.prototype.onKeyDown = (function (onKeyDown) {
            return function (e: any) {
                if (e.keyCode == 13 && currentlyEditingEntity != undefined) {
                    currentlyEditingEntity.stopEditing();
                    return;
                }
                onKeyDown.call(this, e);
            };
        })(fabric.IText.prototype.onKeyDown);

        this.#canvas.on("mouse:down", (options) => {
            localContext.contextualMenu.showMenu(false);

            if (
                currentlyEditingEntity !== undefined &&
                options.target !== currentlyEditingEntity.textBox
            ) {
                currentlyEditingEntity.stopEditing();
            }

            let event = options.e;
            let canvasPos = { x: 0, y: 0 };
            switch (this.#toolBarManager.currentTool()) {
                case "cursor":
                    break;
                case "pencil":
                    break;
                case "sticky":
                    canvasPos = localContext.camera.screenToCanvasPos(event.clientX, event.clientY);
                    let newSticky = new StickyNote(canvasPos.x, canvasPos.y, "");
                    localContext.activity.addEntity(newSticky);
                    newSticky.enterEditing();
                    this.#toolBarManager.onToolSelected("cursor");
                    break;
                case "text":
                    canvasPos = localContext.camera.screenToCanvasPos(event.clientX, event.clientY);

                    let newTextBox = new TextBox(canvasPos.x, canvasPos.y, "");
                    localContext.activity.addEntity(newTextBox);
                    newTextBox.enterEditing();
                    this.#toolBarManager.onToolSelected("cursor");
                    break;
            }
            if (event.button == 2 || event.button == 1) {
                localContext.camera.startPan(event.clientX, event.clientY);
            }
        });

        this.#canvas.on("mouse:move", (opt) => {
            var event = opt.e;
            this.#canvas.setCursor(this.#toolToCursor[this.#toolBarManager.currentTool()]);
            localContext.camera.pan(event.clientX, event.clientY);
            if (localContext.camera.cameraPanState === CameraPanState.Dragging) {
                localContext.contextualMenu.showMenu(false);
                this.#toolBarManager.enableHomeButton(true);
            }
        });

        this.#canvas.on("mouse:up", (options) => {
            let event = options.e;
            if (event.button == 2 && localContext.camera.cameraPanState !== CameraPanState.Dragging) {
                if (options.target !== null) {
                    this.#canvas.setActiveObject(options.target!);
                }
                localContext.contextualMenu.showMenu(true);
                localContext.contextualMenu.setMenuPosition(event.clientX, event.clientY);
            }
            localContext.camera.stopPan();
        });

        this.#canvas.on("mouse:wheel", (opt) => {
            localContext.camera.zoom(opt.e.deltaY, opt.e.offsetX, opt.e.offsetY);
            opt.e.preventDefault();
            opt.e.stopPropagation();
            this.#toolBarManager.enableHomeButton(true);
        });
    }

    constructor(canvas: fabric.Canvas, toolBarManager: ToolBarManager) {
        this.#toolToCursor["cursor"] = "default";
        this.#toolToCursor["pencil"] = "crosshair";
        this.#toolToCursor["sticky"] = "text";
        this.#canvas = canvas;
        this.#toolBarManager = toolBarManager;

        window.addEventListener("home_button:pressed", (_: any) => {
            localContext.camera.fitToContent();
            this.#toolBarManager.enableHomeButton(false);
        });
        window.addEventListener("tool_changed", (event: CustomEventInit) => {
            this.onToolChanged(event.detail.toolName);
        });
        window.addEventListener("side_bar:delete", (_) => {
            let activeObjects = canvas.getActiveObjects();
            activeObjects.forEach((ao) => {
                canvas.remove(ao);
                if (ao.data !== null) {
                    localContext.activity.removeEntity(ao.data);
                }
            });
            canvas.discardActiveObject(undefined);
            localContext.activity.store();
        });

        window.addEventListener("side_bar:lock", (_) => {
            //let activeObjects = canvas.getActiveObjects();
            //activeObjects.forEach(ao => {
            //    if (ao.data !== null)
            //    {
            //        const entity = ao.data as IEntity;
            //    }
            //});
        });

        window.addEventListener("side_bar:change_color", (event: CustomEventInit) => {
            let activeObjects = canvas.getActiveObjects();

            let queue = new Queue<fabric.Object>();
            activeObjects.forEach((ao) => queue.enqueue(ao));
            let shouldRenderAll = false;

            while (!queue.isEmpty()) {
                let top: fabric.Object = queue.peek()!;
                queue.dequeue();
                if (top.isType("group")) {
                    let group: fabric.Group = top as fabric.Group;
                    group._objects.forEach((ao) => queue.enqueue(ao));
                } else if (top.isType("stickyRect")) {
                    let stickyRect = top as StickyRect;
                    stickyRect.stickyNote.setColor(event.detail.colorHex);
                    shouldRenderAll = true;
                } else if (top.isType("path")) {
                    top.set("stroke", event.detail.colorHex);
                    shouldRenderAll = true;
                }
            }
            if (shouldRenderAll) {
                canvas.requestRenderAll();
            }
        });
        window.addEventListener("load_url_image", (event: CustomEventInit) => {
            let canvasPos = localContext.camera.screenToCanvasPos(
                event.detail.mouseX,
                event.detail.mouseY
            );
            let image = new ImageUpload(canvasPos.x, canvasPos.y, 0.3, 0.3, 0, event.detail.url);
            image.onLoaded = () => localContext.activity.addEntity(image);
        });

        window.addEventListener("contextual_menu:copy", () => {
            let activeObjects = canvas.getActiveObjects();
            this.#clipBoard = localContext.activity.serializeObjects(activeObjects);
            localContext.contextualMenu.showMenu(false);
        });
        window.addEventListener("contextual_menu:paste", (event: CustomEventInit) => {
            const canvasPos = localContext.camera.screenToCanvasPos(
                event.detail.mousePos.x,
                event.detail.mousePos.y
            );
            localContext.activity.loadEntitiesFromJson(this.#clipBoard, canvasPos);
            localContext.contextualMenu.showMenu(false);
            localContext.activity.store();
        });
        window.addEventListener("contextual_menu:sendToBack", () => {
            let activeObjects = canvas.getActiveObjects();
            activeObjects.forEach((ao) => ao.sendToBack());
            localContext.contextualMenu.showMenu(false);
            localContext.activity.canvas.requestRenderAll();
            localContext.activity.store();
        });
        window.addEventListener("contextual_menu:sendToFront", () => {
            let activeObjects = canvas.getActiveObjects();
            activeObjects.forEach((ao) => ao.bringToFront());
            localContext.contextualMenu.showMenu(false);
            localContext.activity.canvas.requestRenderAll();
            localContext.activity.store();
        });
        this.initMouseKeyboardEvents();
    }
}

export default ControlsManager;
