import { fabric } from "fabric";

export interface ITextEntity {
    enterEditing(): void;
    stopEditing(): void;
    get textBox(): fabric.Textbox;
}

let currentlyEditingEntity: ITextEntity | undefined = undefined;

function setCurrentlyEditingEntity(entity: ITextEntity | undefined)
{
    currentlyEditingEntity = entity;
}

export {currentlyEditingEntity, setCurrentlyEditingEntity};