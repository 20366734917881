import { fabric } from "fabric";

export interface IEntity {
    serialize(): {} | undefined;    
    deserialize(object: {}): void;    
    get object(): fabric.Object | undefined;
    lock(isLocked: boolean): void;
}
export function translateEntity(entity: IEntity, offset: {x: number, y: number} = {x: 0, y: 0})
{
    entity.object?.set('left', entity.object.left! + offset.x);
    entity.object?.set('top', entity.object.top! + offset.y);

}
