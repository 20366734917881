export class Queue<T> {
    #elements: T[] = [];
    enqueue(element: any) {
        this.#elements.push(element);
    }
    dequeue() {
        return this.#elements.shift();
    }

    isEmpty() {
        return this.#elements.length == 0;
    };
    peek(): T | undefined {
        return !this.isEmpty() ? this.#elements[0] : undefined;
    };
}
